<template>
  <transition :name="`slide-${slideDirection}`">
    <div
      v-if="items"
      :class="slideDirection === 'up' ? '' : 'autosuggestion--top'"
      class="border rounded shadow autosuggestion"
    >
      <div class="list-group list-group-flush">
        <div
          @click="$emit('select', item)"
          v-for="(item, itemIdx) in items"
          :key="`key-${itemIdx}`"
          class="list-group-item list-group-item-action px-2 small"
          role="button"
        >{{ item.name }}</div>
        <div
          v-if="!items.length"
          class="list-group-item px-2 small text-muted fst-italic"
        >Not found</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'InputSuggestion',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    slideDirection: {
      type: String,
      default: 'up',
    },
  },
  methods: {
    readKey(e) {
      if (e.which === 27) {
        this.$emit('dismiss');
      }
    },
    addListener() {
      window.addEventListener('keyup', this.readKey);
    },
    removeListener() {
      window.removeEventListener('keyup', this.readKey);
    },
  },
  data() {
    return {
      activeIndex: -1,
    };
  },
  created() {
    this.addListener();
  },
  beforeDestroy() {
    this.removeListener();
  },
};
</script>
